.prefooterSocials_container {
  font-family: "__Inter_aaf875", sans-serif;
  h2 {
    font-size: 3.375rem;
  }

  p {
    letter-spacing: -0.4px;
  }

  .socials {
    a {
      display: flex;
      width: 80px;
      height: 80px;
      background: #007739;
      padding: 15px;
      border-radius: 100px;
      transition: 0.5s;

      &:hover {
        color: #007739;
        background: #ffffff;
        transition: 0.5s;
        svg {
          path {
            stroke: #007739;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .prefooterSocials_container {
    text-align: center;

    .socials {
      padding-top: 30px;
      a {
        width: 70px;
        height: 70px;
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .prefooterSocials_container {
    .socials {
      a {
        width: 60px;
        height: 60px;
      }
    }
  }
}
